<template>
	<div class="container">
		<div class="header" @click="expanded = !expanded">
			<span>{{ header }}</span>
		</div>
		<slide-up-down v-model="expanded" :duration="400">
			<div class="content">
				<div v-parse-links v-html="content" />
			</div>
		</slide-up-down>
	</div>
</template>

<script setup>
defineProps({
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});

const expanded = ref(false);
</script>

<style lang="scss" scoped>
.header,
.content {
	font-size: 15px;
	padding: 20px;
	width: 100%;
	border: 1px solid #ccc;
}

.header {
	cursor: pointer;
	background: $primary-background-color;

	&.active {
		border-color: #b33030;
	}
}

.container {
	display: inline-block;
	font-size: 0;
	vertical-align: top;
	text-align: left;
	margin: 0 0 1%;
	width: 100%;

	.content {
		padding: 20px 20px 0;
		border-top: none;
		background: #f9f9f9;
		transition: all 100ms ease;

		ul,
		ol {
			padding: 0 0 0 20px;
		}

		:deep(p) {
			margin: 0 0 20px;
		}
	}

	&.not-expanded .content {
		transform: scaleY(0);
		transform-origin: top;
		opacity: 0;
		height: 0;
		padding: 0 20px;
	}
}
</style>
